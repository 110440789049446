@import "../../../Assets/StyleSheets/Variables/colors.scss";

.navigationBar {
  background-color: $secondary;
  justify-content: center;
  text-align: center;
  flex-wrap: nowrap;
  color: $primary;
  position: fixed;
  z-index: 99999;
  display: flex;
  width: 100%;
  bottom: 0;
}