.blogPostPage {
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
  position: absolute;
  display: flex;
  width: 100%;
  top: 0;

  .blogPostWrapper {
    padding-top: 157px;
    overflow: hidden;
    max-width: 600px;
    margin: auto;
    width: 100%;
  }
}