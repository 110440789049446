@import "../../../Assets/StyleSheets/Variables/colors.scss";

.serviceTableContainer {
  .serviceTable {
    width: 100%;
  
    tr th {
      font-weight: bold;
    }

    .withPadding {
      &__10 {
        padding: 10px;
      }
    }

    .toggleRequestedService {
      cursor: pointer;
    }
  }

  input {
    border-bottom: 0.2px dotted $primary;
    background-color: transparent;
    box-sizing: border-box;
    margin: 30px 0 5px;
    border-right: 0;
    border-left: 0;
    padding: 15px;
    border-top: 0;
    width: 400px;
    float: right;

    &:focus-visible {
      outline: none;
    }
  }

  .pricingButton {
    span {
      float: none;
      font-size: 12px;
      display: block;
    }
  }

  .submitButton {
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
    display: block;
    padding: 15px;
    border-top: 0;
    width: 400px;
    float: right;
    clear: both;
    
    &.unlocked {
      background-color: $primary;
      color: $secondary;
      cursor: pointer;
    }
  
    &.locked {
      background-color: $dactivatedDark;
      color: $dactivatedLight;
    }
  }

  @media (max-width: 600px) {
    .responsive {
      &__collapsedColumn {
        display: none;
      }
    }

    .submitButton {
      float: none;
      width: 300px;
      margin: auto;
    }

    table, tbody, td, thead, tr {
      max-width: 300px;
      margin: auto;
    }

    & input {
      float: none;
      margin: 20px auto;
      width: 300px;
    }

    & .nofifications {
      float: none;
      width: 300px;
      margin: auto;
  }
  }
}