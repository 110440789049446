
.serviceOfferPage {
  width: 100%;
  box-sizing: content-box;
  top: 0;

  .paper {
    width: 900px;
    margin: 60px auto;
    padding: 20px;
    box-shadow: 1px 1px 1px 1px black;
  }

  select {
    font-size: 1em;
    border: 0;
  }
}

.documentRecipientBox {
  float: left;
  text-align: left;
  
  td {
    padding: 3px 0px
  }
}

.documentInformationBox {
  float: right;

  td {
    padding: 3px 0 3px 14px
  }
}

.documentFooterBox {
  margin: auto;
  width: fit-content;

  td {
    padding: 3px 0 3px 14px
  }
}