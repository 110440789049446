.grid {
  box-sizing: border-box;

  &.wrapper {
    max-width: 1900px;
    margin: 50px auto;
  }

  &.clear {
    clear: both;
  }

  &.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }

  &.spacing {
    &__5 {
      padding: 5px;
    }

    &__10 {
      padding: 10px;
    }
  }

  &.columns {
    &__4 {
      width: 25%;
      float:left;
    }
    
    &__3 {
      width: 33.333%;
      float:left;
    }
    
    &__2 {
      width: 50%;
      float:left;
    }

    &__1 {
      width: 100%;
      float:left;
    }
  }
}