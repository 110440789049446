@import "../../../../Assets/StyleSheets/Variables/colors.scss";

.navigationLink {
  border-top: 1px solid $primary;
  margin: 0 5px;
  
  > a {
    text-decoration: none;
    padding: 15px 10px;
    color: $primary;
    float: left;
  }

  &.currentPage, &:hover {
    background-color: $primary;

    > a {
      color: $secondary;
    }
  }
}

.currentPage {
  background-color: $primary;
}
