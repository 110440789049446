.spacer {
  width: 100%;

  &.space {
    &__200 {
      height: 200px;
    }

    &__100 {
      height: 100px;
    }
  
    &__50 {
      height: 50px;
    }
  
    &__20 {
      height: 20px;
    }
  }
}
