.logoLink {
  justify-content: center;
  text-decoration: none;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 100;
  display: flex;

  > .logoText {
    text-shadow: 0px 0px 0 white;
    text-decoration: none;
    color: #131313;
    transition: 0.3s;
    padding: 0 10px;
    font-size: 2em;

    // &:hover {
    //   transition: 0.3s;
    //   text-shadow: 1px -1px 0 grey, 1px -2px 0 grey, 1px -3px 0 grey,
    //     1px -4px 0 grey, 1px -5px 0 grey, 1px -6px 0 grey, 1px -7px 0 grey,
    //     1px -8px 0 grey, 5px -13px 15px black, 5px -13px 25px #808080;
    // }
  }
}