@import "../../../Assets/StyleSheets/Variables/colors.scss";

.divider {
  svg {
    line {
      stroke: $primary;
      stroke-width: 1;
    }
  }

}