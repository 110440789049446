.rotatingImage {
  height: 40vmin;
  pointer-events: none;
}

@keyframes rotatingImageSpinRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotatingImageSpinLeft {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);  
  }
}
