@import "../../../Assets/StyleSheets/Variables/colors.scss";

.button {
  border-radius: 5px 5px 5px 5px;
  background-color: $primary;
  border: 1px solid $primary;
  box-sizing: border-box;
  cursor: pointer;
  color: $secondary;
  display: block;
  border-top: 0;
  padding: 30px;
  width: 100%;
  float: none;
  clear: both;
  
}
