@import "../../../Assets/StyleSheets/Variables/colors.scss";

.loadingModal {
  justify-content: center;
  border-color: $primary;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

  img {
    animation: rotatingImageSpin infinite 2s linear;
  }
}