@import "../../../Assets/StyleSheets/Variables/colors.scss";

@font-face {
  font-family: 'Roboto';
  src: url('../../../Assets/Fonts/Roboto/Roboto-Thin.ttf') format('truetype'); 
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../Assets/Fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype'); 
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../Assets/Fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../Assets/Fonts/Roboto/Roboto-Italic.ttf') format('truetype'); 
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../Assets/Fonts/Roboto/Roboto-Regular.ttf') format('truetype'); 
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../Assets/Fonts/Roboto/Roboto-Bold.ttf') format('truetype'); 
  font-weight: 600;
}

.dataModelers {
  * {
    // transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    font-family: 'Roboto', sans-serif;
  }

  text-align: center;
  color: $primary;
  height: 100vh;

  &.dark {
    color: $secondary;
    background-color: $primary;
  }

  &.hacker {
    color: $secondary;
    background-color: $blue;
  }
}