@import "../../../Assets/StyleSheets/Variables/colors.scss";

.notFoundPage {
  h1 {
    animation: glitch 1s linear infinite;

    &:before,
    &:after {
      content: attr(title);
      position: absolute;
      left: 0;
    }

    &:before {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
      clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
      animation: glitchTop 1s linear infinite;
    }

    &:after {
      -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
      clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
      animation: glitchBottom 1.5s linear infinite;
    }
  }

  justify-content: center;
  align-items: center;
  color: $primary;
  margin: 0;
}

@keyframes glitch {
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

@keyframes glitchTop {
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

@keyframes glitchBottom {
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}