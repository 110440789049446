@import "../../../Assets/StyleSheets/Variables/colors.scss";

.imprintPage {
  .imprintWrapper {
    word-break: break-all;
    white-space: revert;
    width: 100%;
  }

  .imprintButton {
    background-color: $primary;
    display: inline-block;
    cursor: pointer;
    color: $secondary;
    padding: 15px;
    margin: 10px;
    width: auto;
  }
}