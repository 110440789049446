@import "../../../Assets/StyleSheets/Variables/colors.scss";

.tokenModal {
  justify-content: center;
  border-color: #131313;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  width: 100vw;
  left: 0;
  top: 0;

  .errors {
    color: $warning;
  }

  * {
    background-color: transparent;
    font-size: 1em;
    padding: 5px;
  }

  input {
    padding: 30px;
  }

  .enterCredentials {
    max-width: 230px;
    margin: auto;

    .classicalButton,
    .tokenSubmitButton {
      border-radius: 0px 0 5px 5px;
      background-color: $primary;
      color: $secondary;
      display: block;
      cursor: pointer;
    }

    .tokenInput,
    .userNameInput,
    .passwordInput,
    .classicalButton,
    .tokenSubmitButton {
      box-sizing: border-box;
      display: block;
      width: 100%;
      float: none;
      clear: both;
    }

    .userNameInput,
    .tokenInput {
      border-radius: 5px 5px 0 0;
      border-bottom: 0;
    }

    .passwordInput {
      border-bottom: 0;
      border-radius: 0;
    }

    .button {
      border: 1px solid $primary;

      &:hover {
        background-color: $primary;
        color: $secondary;
      }
    }

    input {
      border: 1px solid $primary;
      margin: 0;
      box-sizing: content-box;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .switchMethod {
    text-decoration: underline;
    cursor: pointer;
  }
}