@import "../../../Assets/StyleSheets/Variables/colors.scss";

.serviceOfferListElement {
  box-shadow: 1px 1px 1px 1px $primary;
  padding: 20px;
  box-sizing: border-box;
  width: 320px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 5px;
  color: $primary;

  .pulse {
    border-radius: 50%;
    margin: 3px;
    height: 10px;
    width: 10px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
    float: left;
  } 

  & .approval {
    background: $warning;
    box-shadow: 0 0 0 0 $warning;
    animation: pulse-approval 2s infinite;
  }

  & .accepted {
    background: rgb(217, 151, 51);
    box-shadow: 0 0 0 0 $success;
    animation: pulse-approval 2s infinite;
  }
}


@keyframes pulse-approval {
  0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 127, 80, 0.7);
  }
  
  70% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(255, 127, 80, 0);
  }
  
  100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 127, 80, 0);
  }
}

@keyframes pulse-accepted {
  0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 $success;
  }
  
  70% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  
  100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}