@import "../../../Assets/StyleSheets/Variables/colors.scss";

.screenSaverContainer {
    background: $primary;
    overflow: hidden;
    position: fixed;
    z-index: 99999;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    .screenSaver {
        position: absolute;
        color: $primary;
    }

    .screenSaverCheckPositions {
        position: absolute;
        color: $secondary;
    }
}
