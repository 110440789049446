@import "../../../Assets/StyleSheets/Variables/colors.scss";

.headerImage {
  box-shadow: 0px 1px 1px 0 $primary;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 156px;
  width: 100%;
  left: 0;
  top: 0;
}