@import "../../../Assets/StyleSheets/Variables/colors.scss";

.nofifications {
  width: 400px;
  float: right;

  .banner {
    padding: 10px;

    &.warn {
      background-color: $success;
    }

    &.info {
      background-color: $warning;
    }
  }  
}
