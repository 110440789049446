@import "../../../Assets/StyleSheets/Variables/colors.scss";

.card {
  box-shadow: 1px 1px 1px 1px $primary;
  text-decoration: none;
  margin-bottom: 30px;
  background: $secondary;
  color: $primary;
  display: block;
  margin: auto;
  width: 100%;

  &:hover {
    box-shadow: 5px 5px 15px 0 $primary;
  }
}

@media (max-width: 600px) {
  .card {
    width: 300px;
    margin: auto;
  }
}
