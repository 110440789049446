@import "../../../Assets/StyleSheets/Variables/colors.scss";

.rotatingText {
  font-weight: 100;
  font-size: 4em;

  > span {
    &.text {
      display: block;
      float: none;
    }

    &.rotatingWord {
      color: $warning;
      margin: 0 4px;
    }

    &.active {
      display: initial;
    }

  }

  .blinkingCursor {
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
  }  
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: $primary;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: $primary;
  }
}
