@import "../../../Assets/StyleSheets/Variables/colors.scss";

.typography {
  &.textAlign {
    &__left {
      text-align: left;
    }

    &__right {
      text-align: right;
    }

    &__center {
      text-align: center;
    }
  }

  &.fontWeight {
    &__600 {
      font-weight: 600;
    }
  }

  &.fontStyle {
    &__italic {
      font-style: italic;
    }
  }

  &.fontSize {
    &__small {
      font-size: 0.8em;
    }

    &__regular {
      font-size: 1em;
    }

    &__extraLarge {
      font-size: 4em;
    }

    &__extraExtraLarge {
      font-size: 5em;
    }
  }

  .markdown {
    line-height: 2em;

    img {
      box-shadow: 0 0 7px 0 $primary;
      border-radius: 5px;
      max-width: 550px;
      width: 100%;
    }
  }

  .subtitle {
    white-space: nowrap;
    font-weight: 100;
    padding: 20px 0;
    display: block;
  }

  .description {
    font-weight: 200;
    display: block;
  }

  .tableHeader {
    font-weight: 400;
    display: block;
  }

  .caption {
    padding: 0 20px 40px;
    font-weight: 200;
    display: block;
  }

  h1 {
    margin: 20px 0 50px;
    font-weight: 100;
    font-size: 2em;
  }

  h2 {
    margin: 15px 30px;
    font-weight: 100;
    font-size: 1.5em;
  }

  h3 {
    margin: 10px 10px;
    font-weight: 100;
    font-size: 1em;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  p {
    padding: 15px 0;
  }
}
